import type { GetStaticProps } from 'next';

import Page from '@/components/Pages/Page';
import { getFooterContent } from '@/lib/graphql/footer';
import { getHomepageContent } from '@/lib/graphql/homepage';

export const getStaticProps: GetStaticProps = async ({ preview }) => {
  const content = await getHomepageContent(!!preview);
  const footer = await getFooterContent(!!preview);

  return {
    props: {
      content,
      footer,
    },
    // revalidate: 3600,
  };
};

export default Page;
